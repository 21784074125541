import React from "react"
import { graphql, Link } from "gatsby"

import { GlobalStyle } from "../styles"
import SEO from "../components/seo"

import Nav from "../segments/Nav"
import Footer from "../segments/Footer"

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map((edge, index) => (
      <>
        <div className="content is-medium">
          <Link to={edge.node.frontmatter.path}>
            <h2 className="title">{edge.node.frontmatter.title}</h2>
            <p className="subtitle">{edge.node.excerpt}</p>
          </Link>
        </div>
        {index !== edges.length - 1 && (
          <div
            style={{
              borderBottom: "1px solid #d6d6d6",
              marginBottom: "1.5rem",
            }}
          />
        )}
      </>
    ))

  return (
    <React.Fragment>
      <GlobalStyle />
      <SEO title="Learn React Native by Example | Comprehensive React Native Course" />
      <Nav isFixedTop={false} />

      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2">{Posts}</div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { published: { eq: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
          }
        }
      }
    }
  }
`
